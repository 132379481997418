@charset "UTF-8";
@import '../../assets/scss/colors.scss';
@import '../../assets/scss/variables.scss';
@import '../../assets/scss/fonts.scss';

.sectionBanner {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    object-fit: cover;
    margin: auto;
    padding: 0;
    display: block;
    height: 646px;
    position: relative;

    .titleBanner {
        padding: 0;
        display: block;
        position: relative;
        top: 380px;
        width: 100%;
    }

    @media only screen and (max-width: 600px) {
        .titleBanner {
            font-size: 45px !important;
            top: 280px;
        }
    }

    .buttonsBanner {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        top: 410px;
        width: 100%;

        .MuiButton-contained{
            width: 140px !important;
            height: 50px;
        }

        @media only screen and (max-width: 600px) {
            .MuiButton-contained{
                width: 100% !important;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .buttonsBanner{
            top: 330px;
        }
    }

    .sectionSearch {
        background-color: #fff;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
        border-radius: 14px;
        -webkit-box-shadow: 0 0 10px #0327535F;
        -moz-box-shadow: 0 0 10px #0327535F;
        -ms-box-shadow: 0 0 10px #0327535F;
        -o-box-shadow: 0 0 10px #0327535F;
        box-shadow: 0 0 10px #0327535F;
        padding: 30px 30px 0 30px;
        top: 470px;
        height: 120px;
        display: block;
        position: relative;
        width: 100%;
        z-index: 10;
    }

    @media only screen and (max-width: 600px) {
        .sectionSearch{
            top: 390px;
        }
    }
}

.sectionContent {
    background-color: #ffffff;
    margin: 0;
    padding-top: 100px;
    padding-bottom: 100px;
    display: block;
    position: relative;
    width: 100%;
}

.borderLR{
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: $color-primary;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: $color-primary;
}
