@charset "UTF-8";
@import '../../assets/scss/colors.scss';
@import '../../assets/scss/fonts.scss';
@import '../../assets/scss/variables.scss';

.sectionLogo {
  float: left;
  margin: 0;
  padding-top: 5px;
  position: relative;
  display: block;
  width: 150px;
}

.sectionNav {
  float: left;
  margin-left: 50px;
  position: relative;

  .nav-pills {
    .nav-link{
      padding: 20px 30px !important;

      &.active,
      &.show>.nav-link {
        background-color: $color-primary-light2;
      }

      &:hover {
        background-color: $color-primary-light2;
      }
    }
  }
}

.sectionLogin {
  float: right;
  padding-top: 5px;
  position: relative;
  display: block;
}

.mainContent{
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
}

footer{
  background-color: transparent !important;
  display: block;
  position: relative;
  padding: 40px 0 30px 0;
  width: 100%;
}

@media only screen and (max-width: 600px) {
    footer {
        text-align: center;
        margin: auto;

        .sectionLogo{
            margin: auto;
            display: block;
        }
    }
}
