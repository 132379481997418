@charset "UTF-8";
@import './assets/scss/colors.scss';
@import './assets/scss/variables.scss';
@import './assets/scss/fonts.scss';

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  background-color: $color-body;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  overflow-x: hidden !important;
}

.auto140{
  width: 140px !important;
}

@media only screen and (max-width: 600px) {
  .auto140 {
    width: 100% !important;
  }
}

.back-to-top{
  margin: 0 !important;
  bottom: 30px !important;
  right: 30px !important;
}

.container {
  margin: 0 auto;
  width: 1200px;
  padding: 0;
  display: block;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .container {
    margin-left: 2% !important;
    margin-right: 2% !important;
    width: 96% !important;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: none !important;
  }
}

@media only screen and (min-width: 576px) {
  .container {
    max-width: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .d-xs-none {
    display: none;
    visibility: hidden;
  }
}

.bg-primary{
   background-color: $color-primary;
}

.bg-primary-hover{
   background-color: $color-primary-hover;
}

.bg-primary-light{
   background-color: $color-primary-light;
}

.bg-primary-light2{
   background-color: $color-primary-light2;
}

.btn-outline{
  border: 1px solid $color-primary;
  box-shadow: $box-shadow-none;
  outline: 0 !important;
  padding: 0 !important;
}

.btn-outline-white{
    background-color: $color-white;
    border: 1px solid $color-primary;
    box-shadow: $box-shadow-none;
    color: $color-primary;
    outline: 0 !important;

    &:hover{
        background-color: $color-primary;
        border: 1px solid $color-primary;
        color: $color-white;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }

    &.active{
        background-color: $color-primary;
        border: 1px solid $color-primary;
        color: $color-white;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }
}

.btn-outline-primary-light{
  background-color: $color-primary-light2;
  border: 1px solid $color-primary;
  box-shadow: $box-shadow-none;
  outline: 0 !important;
}

.btn-outline-primary{
    background-color: $color-primary;
    border: 2px solid $color-primary;
    box-shadow: $box-shadow-none;
    outline: 0 !important;

    &:hover{
        background-color: $color-primary-hover;
        border: 2px solid $color-primary-hover;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }

    &:focus{
        background-color: $color-primary-hover;
        border: 2px solid $color-primary-hover;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }

    &:active{
        background-color: $color-primary-hover;
        border: 2px solid $color-primary-hover;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }
}

.btn-Dropdown-light{
    background-color: $color-primary-light2;
    color: $color-primary;
    border: 2px solid $color-primary;
    box-shadow: $box-shadow-none;
    outline: 0 !important;
    text-align: left;

    &:after{
      float: right;
      right: 0;
      top: 10px;
      position: relative;
    }

    &:hover{
        border: 2px solid $color-primary-hover;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }

    &:focus{
        border: 2px solid $color-primary-hover;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }

    &:active{
        background-color: $color-primary-hover;
        border: 2px solid $color-primary-hover;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }
}

.border-primary {
    box-shadow: $box-shadow-none;
    outline: 0 !important;
    border-color: $color-primary;
}

.border-bottom-primary{
  height: .5px;
  display: block;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 0;
  position: relative;
  background-color: $color-primary-hover;
  width: 100%;
}

.color-white {
  color: $color-white;
}

.color-dark {
  color: $color-dark;
}

.color-primary {
  color: $color-primary;
}

.color-title {
  color: $color-title;
}

.color-title-footer {
  color: $color-title-footer;
}

.color-powered {
  color: $color-powered;
}

.cursor-pointer{
  cursor: pointer;
}

.dropdown-item{
  background-color: $color-white;

  &:hover{
    background-color: $color-default;
  }
  &:focus{
    background-color: $color-default;
  }
  &:active{
    background-color: $color-default;
  }
}

.font-size-12{
  font-size: 12px !important;
}

.font-size-13{
  font-size: 13px !important;
}

.font-size-14{
  font-size: 14px !important;
}

.font-size-16{
  font-size: 16px !important;
}

.font-size-18{
  font-size: 18px !important;
}

.font-size-20{
  font-size: 20px !important;
}

.font-size-22{
  font-size: 22px !important;
}

.font-size-24{
  font-size: 24px !important;
}

.font-size-26{
  font-size: 26px !important;
}

.font-size-28{
  font-size: 28px !important;
}

.font-size-30{
  font-size: 30px !important;
}

.font-size-40{
  font-size: 40px !important;
}

.font-size-50{
  font-size: 50px !important;
}

.form-control-primary{
    border: 2px solid $color-primary;
    box-shadow: $box-shadow-none;
    outline: 0 !important;

    &::placeholder {
        color: $color-placeholder-primary;
    }

    &:hover{
        border: 2px solid $color-primary-hover;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }

    &:focus{
        border: 2px solid $color-primary;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }
}

.form-control-primary2{
    cursor: pointer;
    font-family: $font-raleway;
    font-weight: $font-bold;
    color: $color-primary;
    border: 2px solid $color-primary;
    box-shadow: $box-shadow-none;
    outline: 0 !important;

    &::placeholder {
        color: $color-placeholder-primary;
    }

    &:hover{
        border: 2px solid $color-primary-hover;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }

    &:focus{
        border: 2px solid $color-primary;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }
}

.form-control-md{
    cursor: pointer;
    font-family: $font-raleway;
    font-weight: $font-medium;
    color: $color-primary;
    border: 1px solid $color-primary;
    box-shadow: $box-shadow-none;
    outline: 0 !important;

    &::placeholder {
        color: $color-placeholder-primary;
    }

    &:hover{
        border: 1px solid $color-primary-hover;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }

    &:focus{
        border: 1px solid $color-primary;
        box-shadow: $box-shadow-none;
        outline: 0 !important;
    }
}

.float-right{
  float: right !important;
  right: 0;
}

.height-1 {
  height: 1px !important;
}

.height-15 {
  height: 15px !important;
}

.height-20 {
  height: 20px !important;
}

.height-24 {
  height: 24px !important;
}

.height-25 {
  height: 25px !important;
}

.height-30 {
  height: 30px !important;
}

.height-31 {
  height: 31px !important;
}

.height-43 {
  height: 43px !important;
}

.height-40 {
  height: 40px !important;
}

.height-50 {
  height: 50px !important;
}

.height-60 {
  height: 60px !important;
}

.height-70 {
  height: 70px !important;
}

@media only screen and (max-width: 600px) {
    .justify-content-sm-center {
        text-align: center !important;
    }
}

.mb--40 {
  margin-bottom: -40px;
}

.mr-5{
  margin-right: 5px;
}

@media only screen and (max-width: 600px) {
  .mt-7{
    margin-top: 7px !important;
    position: relative;
  }
}

.noBorderRadius{
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  border-radius: 0 !important;
}

.padding-10{
  padding: 10px !important;
}

.padding-15{
  padding: 15px !important;
}

.page-item {
  outline: 0 !important;
  box-shadow: $box-shadow-none;

  &.active {
      .page-link {
          background-color: $color-primary;
          border-color: $color-primary;
      }
  }

  .page-link {
    outline: 0 !important;
    box-shadow: $box-shadow-none;
  }
}

.subheader{
  display: none;
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 10px #0327535F;
  -moz-box-shadow: 0 0 10px #0327535F;
  -ms-box-shadow: 0 0 10px #0327535F;
  -o-box-shadow: 0 0 10px #0327535F;
  box-shadow: 0 0 10px #0327535F;
  -webkit-transform: translateY(-70px);
  -moz-transform: translateY(-70px);
  -ms-transform: translateY(-70px);
  -o-transform: translateY(-70px);
  transform: translateY(-70px);
  position: fixed;
  margin: 0;
  padding: 10px;
  width: 100%;
  z-index: 100;
}

.subheader.sticky{
  display: block;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

@media only screen and (max-width: 600px) {
  .subheader {
    .MuiButton-root {
      width: 100% !important;
    }
  }
}

.text-left{
  text-align: left !important;
}

.text-right{
  text-align: right !important;
}

.text-primary{
  color: $color-primary;
}

.top-2 {
  top: 2px;
  position: relative;
}

.top--6 {
  top: -6px;
  position: relative;
}

.top--8 {
  top: -8px;
  position: relative;
}

.width-100p {
  width: 100% !important;
}

.width-10 {
  width: 10px !important;
}

.width-15 {
  width: 15px !important;
}

.width-20 {
  width: 20px !important;
}

.width-30 {
  width: 30px !important;
}

.width-32 {
  width: 32px !important;
}

.width-40 {
  width: 40px !important;
}

.width-60 {
  width: 60px !important;
}

.width-140 {
  width: 140px !important;
}

.z-index-100 {
  z-index: 100 !important;
}
