@charset "UTF-8";
@import '../../assets/scss/colors.scss';
@import '../../assets/scss/variables.scss';
@import '../../assets/scss/fonts.scss';

.sectionSearchContent {
    padding: 50px 0 50px 0;
    display: block;
    position: relative;
    width: 100%;

    .sectionSearch {
        background-color: #fff;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
        border-radius: 14px;
        -webkit-box-shadow: 0 0 10px #0327535F;
        -moz-box-shadow: 0 0 10px #0327535F;
        -ms-box-shadow: 0 0 10px #0327535F;
        -o-box-shadow: 0 0 10px #0327535F;
        box-shadow: 0 0 10px #0327535F;
        padding: 30px 30px 30px 30px;
        top: 110px;
        height: 120px;
        display: block;
        position: relative;
        width: 100%;
        z-index: 10;
    }

    @media only screen and (max-width: 600px) {
        .sectionSearch {
            height: auto !important;
            top: 110px !important;

            .MuiButton-root {
                width: 100% !important;
            }
        }
    }
}

.cardContent{
    background-color: #ffffff;
    margin: 0;
    padding-top: 100px;
    padding-bottom: 50px;
    display: block;
    position: relative;
    width: 100%;
}

.card-media{
    background-color: $color-dark-light;
    height: 250px;
    background-size: contain;
    padding-top: 56.25%;
    object-fit: cover;

    div{
        top: -50%;
    }
}

.drawer {
    background-color: $color-light;
    overflow-y: auto;
    overflow-x: hidden;
    width: 330px;

    .paper{
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
        width: 330px;

        .drawer-header{
            background-color: $color-primary-hover;
            display: flex;
            -webkit-justify-content: flex-end;
            -moz-justify-content: flex-end;
            -ms-justify-content: flex-end;
            -o-justify-content: flex-end;
            justify-content: flex-end;
            color: $color-light;
            padding: 20px 20px 0 20px;
            height: 60px;

            .icon-drawer-header{
                float: right;
                text-align: right;
                right: -12px;
                top: -13px;
            }
        }

        .sectionTags{
            border-bottom: 1px solid $color-primary-hover;
            display: block;
            padding: 0 0 10px 0;
            width: 100%;
            position: relative;

            .sectionTagsHeader {
                padding: 10px 0 0 10px;
                display: inline-block;
                width: 100%;
                position: relative;

                .sectionTagsHeaderButtonIcon{
                    position: relative;
                    top: -1px;
                    margin-left: 5px;
                    margin-right: 10px;

                    .MuiIconButton-root {
                        padding: 5px !important;
                        font-size: 14px !important;
                        .MuiSvgIcon-root{
                            font-size: 14px !important;
                            color: $color-primary;
                        }
                    }
                }
            }

            .sectionTagsFooter {
                display: inline-block;
                width: 100%;
                padding: 10px 10px 0 10px;
                position: relative;

                .badge {
                    background-color: #F2F8FF;
                    color: #2435C8;
                    font-size: 14px;
                    font-weight: 400;
                    padding: 10px 15px 0px 15px;
                    margin-right: 5px;
                    margin-bottom: 5px;
                    -webkit-border-radius: 20px;
                    -moz-border-radius: 20px;
                    -ms-border-radius: 20px;
                    -o-border-radius: 20px;
                    border-radius: 20px;
                    border: 1px solid #2435C8;
                    float: left;
                    display: block;
                    position: relative;

                    span.badgeIcon{
                        float: right;
                        text-align: right;
                        position: relative;
                        top: -6px;
                        right: -5px;

                        .MuiIconButton-root {
                            padding: 5px !important;
                            font-size: 14px !important;
                            .MuiSvgIcon-root{
                                font-size: 14px !important;
                                color: #2435C8;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .drawer {
        width: 100% !important;

        .paper {
            width: 100% !important;
        }
    }
}
