@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swapp');

$font-bold: 700 !important;
$font-semibold: 600 !important;
$font-medium: 500 !important;
$font-regular: 400 !important;
$font-light: 300 !important;
$font-raleway: 'Raleway', sans-serif !important;
$font-open-sans: 'Open Sans', sans-serif !important;

.font-family-raleway-bold{
  font-family: $font-raleway;
  font-weight: $font-bold;
}

.font-family-raleway-semibold{
  font-family: $font-raleway;
  font-weight: $font-semibold;
}

.font-family-raleway-medium{
  font-family: $font-raleway;
  font-weight: $font-medium;
}

.font-family-raleway-regular{
  font-family: $font-raleway;
  font-weight: $font-regular;
}

.font-family-raleway-light{
  font-family: $font-raleway;
  font-weight: $font-light;
}

.font-family-open-sans-bold{
  font-family: $font-open-sans;
  font-weight: $font-bold;
}

.font-family-open-sans-semibold{
  font-family: $font-open-sans;
  font-weight: $font-semibold;
}

.font-family-open-sans-medium{
  font-family: $font-open-sans;
  font-weight: $font-medium;
}

.font-family-open-sans-regular{
  font-family: $font-open-sans;
  font-weight: $font-regular;
}

.font-family-open-sans-light{
  font-family: $font-open-sans;
  font-weight: $font-light;
}