$color-body: #F7FBFF !important;
$color-title: #1F2B33 !important;
$color-title-footer: #2334C7 !important;
$color-powered: #7280E5 !important;
$color-dark: #272727 !important;
$color-dark-light: #888888 !important;
$color-semidark: #444444 !important;
$color-primary-light: #FAFCFF !important;
$color-primary-light2: #F2F8FF !important;
$color-placeholder-primary: #3A50D3 !important;
$color-primary: #2435C8 !important;
$color-primary-hover: #064087 !important;
$color-default: #f1f1f1 !important;
$color-light: #f9f9f9 !important;
$color-white: #ffffff !important;
